@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: clamp(0.5px, 0.12vw, 0.8px);
  position: relative !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

p {
  margin: 0px;
  padding: 0px;
}
